<template>
<!-- 我的医生详情页 -->
    <div class="headr">
       <div class="headr1"> </div>
         <div class="doctor1">
           <div class="doctor2"><img src="../../assets/img/ucenter/2.png" alt=""></div>
           <div class="doctor3">

               <div class="doctor4">
                <span style="color:#333333;padding-right:15px;font-weight: bold ">岳威威</span>
                <span style="font-size: 15px;">主治医生</span>
                <span style="float: right;font-size: 16px;padding-right:25px;padding-top:5px;color:#A4A5AB;">收藏</span>
                    <i  class="iconfont icon-xinaixin"  style="float: right;font-size: 20px;padding-top:5px;padding-right:3px" :class="currentSort==true ?'active':''"  @click="bb"></i>
                <div class="doctor5">心血管内科</div>
               </div>
               <div class="doctor6">
                <div>
                <i  class="iconfont icon-dingwei" style="padding-right:3px"></i>
                <span>门诊二楼东</span>
                </div>
                <div>
               <i  class="iconfont icon-rili"  style="padding-right:3px"></i>
                <span  style="padding-right:20px">周三、周六全天</span>
                </div>
               </div>

               <div class="doctor7">
                    <i  class="iconfont icon-rili"  style="padding-right:5px"></i>
                <span>0371-85901690</span>
               </div>
           </div>
           </div>
           <!-- 中间 -->
           <div class="headr2"> 
             <div class="headr21">
               <div class="headr22">
                <i style="color:#00D48B" class="iconfont icon-jurassic_zan"></i>
                 <span style="padding-left:5px">擅长</span>
               </div>
               <div class="headr23">冠心病、高血压病、心率失常、心力衰竭等心内科疾病的诊治，尤其删除冠状动脉投影术、冠状动脉支架移入术、快速心律失常射频消融术。</div>
             </div>
           </div>
           <div class="headr3">
            
               <div class="headr22">
                <i style="color:#00D48B" class="iconfont icon-jianshen-jiangpai-jinpai"></i>
                 <span style="padding-left:5px">简介</span>
               </div>
              <div class="headr23">       医学硕士，研究生学历，毕业于上海交通大学医学
              院，任河南省某某青年委员，医生简介文案文案。医学
              硕士，研究生学历，毕业于上海交通大学医学院，任河
              南省某某青年委员，医生简介文案文案医学硕士，研究
              生学历，毕业于上海交通大学医学院，任河南省某某青
              年委员，医生简介文案文案医学硕士，研究生学历，毕
              业于上海交通大学医学院，任河南省某某青年委。</div>
           </div>
  </div>
</template>
<script>
export default {
    data(){
        return{
 currentSort:true,
        }
    },
      methods:{
 
    bb(){
       this.currentSort  =  !this.currentSort;
    }
  },

}
</script>
<style scoped>
@import url(//at.alicdn.com/t/font_2783444_8hhcs4oayky.css);
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.body{
background-color: #fff;
}

.active {
   color:#00D48B;
}

.headr{
    width: 100%;
    height: 100%;
}
  .headr1 {
  width:100%;
  height:120px;
  background:url("../../assets/img/ucenter/1.png");
  background-repeat:no-repeat;
  background-size:100% 100%; 
  position: relative;


  }
  .headr2{
    width:9.5rem;
    height:200px;
  color:#83858F;
    margin: auto;
    overflow: hidden;
    font-size: 16px;
    margin-top: 10px;
    border-bottom: 1px solid #D4D4D4;
  }
  .headr21{
       width:9.5rem;  
       height:130px;
       /* background-color: yellow; */
       margin-top: 70px;
  }
  .headr22{
    height:35px;
    line-height: 35px;
    text-align: left;

  }
  .headr23{
    height:90px;

    text-align: left;
    line-height: 30px;
    padding-left: 20px;
  }
  .headr3{
    width:9.5rem;
    height:400px;
   color:#83858F;
    margin: auto;
    font-size: 16px;
    margin-top: 10px;
  }
 .doctor1{
    width:9rem;
    height:160px;
    background-color: #FFFFFF;
    border-radius: 10px;
    margin: auto;
    margin-top: 20px;
    position: absolute;
    top:60px;
    left:5%;
    text-align: left;
    display: flex;
    justify-content: space-between;
    /* background-color: pink; */
}
.doctor2{
     width:50px;
     height:160px;
    text-align: center;
    /* background-color: red; */
    margin-left: 20px;
  
}
.doctor2 img{
    width:50px;
    height: 50px;
    vertical-align:middle;  
      margin-top: 20px
}
.doctor3{
     width:7.5rem;
     height:160px;
    
}
.doctor4{
     width:7.5rem;
     height: 70px;
    
    border-bottom: 1px solid #D4D4D4;
    font-size: 16px;
    margin-top: 20px;
}
.doctor5{
    margin-top: 10px;
    font-size: 14px;

}
.doctor6{
height:35px;

font-size: 14px;
display: flex;
justify-content: space-between;
align-items: center;
color:#A4A5AB;
}
.doctor7{
height:35px;
font-size: 14px;
line-height: 35px;
}
</style>